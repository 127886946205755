import { env } from "@/env.mjs";
import debug from "debug";
import { Logger as AxiomLogger } from "next-axiom";

type LogLevel = "info" | "error" | "debug";

export default class Logger {
  private namespace: string;
  private isServer: boolean;
  private isProduction: boolean;

  constructor(namespace: string, autoLocalDebug = true) {
    this.namespace = namespace;
    this.isServer = typeof window === "undefined";
    this.isProduction = env.NEXT_PUBLIC_LOG_ENV === "production";

    if (autoLocalDebug && !this.isProduction && !this.isServer) {
      localStorage.setItem("debug", "*");
    }
  }

  private logWithDebug(level: LogLevel, message: string, ...args: any[]) {
    const logFn = debug(`snr.portal.${this.namespace}:${level}`);

    if (!this.isProduction) {
      debug.enable(`snr.portal.${this.namespace}:*`);
    }

    logFn.log =
      level === "error"
        ? console.error.bind(console)
        : console.log.bind(console);
    logFn(message, ...args);
  }

  private logWithAxiom(level: LogLevel, message: string, ...args: any[]) {
    if (this.isServer && this.isProduction && !args?.[0]?.ignoreAxiom) {
      const axiomLog = new AxiomLogger();
      const keys = [
        "user.id",
        "user.name",
        "user.email",
        "user.isAdmin",
        "project.name",
        "project.id",
        "project.externalJobCode",
        "project.status",
        "project.planSetPermitStatus",
        "organization.id",
        "organization.name",
        "vendor.email",
        "vendor.name",
      ];

      const sanitizeData = (data: any) => {
        return keys.reduce((acc, key) => {
          const [parent, child]: any = key.split(".");
          if (data[parent]?.[child] !== undefined) {
            acc[parent] = acc[parent] || {};
            acc[parent][child] = data[parent][child];
          }
          return acc;
        }, {} as any);
      };

      const sanitizedArgs = args.map((arg) =>
        typeof arg === "object" ? sanitizeData(arg) : arg,
      );

      if (level === "error") {
        axiomLog.error(message, ...sanitizedArgs);
      } else if (level === "info") {
        axiomLog.info(message, ...sanitizedArgs);
      } else {
        // axiomLog.debug(message, ...sanitizedArgs);
      }
      axiomLog.flush();
    }
  }

  info(message: string, ...args: any[]) {
    this.logWithDebug("info", message, ...args);
    this.logWithAxiom("info", message, ...args);
  }

  error(message: string, ...args: any[]) {
    this.logWithDebug("error", message, ...args);
    this.logWithAxiom("error", message, ...args);
  }

  debug(message: string, ...args: any[]) {
    this.logWithDebug("debug", message, ...args);
    this.logWithAxiom("debug", message, ...args);
  }
}
