import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    CC_SUBMISSION_EMAIL: z.string().optional(),
    BLOB_READ_WRITE_TOKEN: z.string(),
    CLERK_SECRET_KEY: z.string(),
    DATABASE_DIRECT_URL: z.string().url(),
    DATABASE_URL: z.string().url(),
    NODE_ENV: z.enum(["development", "test", "production"]),
    PGDATABASE: z.string(),
    PGHOST: z.string(),
    PGPASSWORD: z.string(),
    PGUSER: z.string(),
    POSTMARK_API_TOKEN: z.string(),
    POSTMARK_TEMPLATE_ACCOUNT_ACTIVATION: z.string(),
    POSTMARK_TEMPLATE_INVITE_USER: z.string(),
    POSTMARK_TEMPLATE_SIGN_IN: z.string(),

    // AWS S3
    AWS_ACCESS_KEY_ID: z.string(),
    AWS_SECRET_ACCESS_KEY: z.string(),
    AWS_REGION: z.string(),
    AWS_BUCKET_NAME: z.string(),

    // PZSE Partner Intergration
    PZSE_URL: z.string(),
    PZSE_ACCOUNT_ID: z.string(),
    PZSE_CLIENT_ID: z.string(),
    PZSE_CLIENT_SECRET: z.string(),

    // Scanifly Partner Integration
    SCANIFLY_API_URL: z.string(),
    SCANIFLY_ACCESS_TOKEN: z.string(),
    SCANIFLY_PARTNER_ORGANIZATION_ID: z.string(),
    SCANIFLY_PARTNER_SYSTEM_USER_ID_ID: z.string(),

    // Amplify Hazards API
    AMPLIFY_API_KEY: z.string(),
    AMPLIFY_URL: z.string(),

    PUBLIC_ORGANIZATION_ID: z.string(),

    PLAYWRIGHT_BASE_URL: z.string(),
    PLAYWRIGHT_CI_USER_EMAIL: z.string(),
    PLAYWRIGHT_CI_USER_PASSWORD: z.string(),
    
    // Customer initially submitted a project to a vendor
    // notify vendor
    POSTMARK_TEMPLATE_PROJECT_SUBMITTED_TO_VENDOR: z.string(),
    // nofity customer project was submitted
    POSTMARK_TEMPLATE_PROJECT_SUBMITTED_TO_VENDOR_NOTIFY_CUSTOMER: z.string(),

    // Customer has requested changes from plans to a vendor - notify vendor
    POSTMARK_TEMPLATE_PROJECT_CHANGES_REQUESTED_TO_VENDOR: z.string(),

    // Vendor has submitted plans to a customer - notify customer
    POSTMARK_TEMPLATE_PLAN_SET_SUBMITTED_TO_CUSTOMER: z.string(),

    // Vendor has requested changes from customer - notify customer
    POSTMARK_TEMPLATE_PLAN_SET_CHANGES_REQUESTED_TO_CUSTOMER: z.string(),

    // Customer has approved plans - notify vendor
    POSTMARK_TEMPLATE_PLAN_SET_APPROVED_SEND_TO_VENDOR: z.string(),

    // Customer has approved plans - notify customer
    POSTMARK_TEMPLATE_PLAN_SET_APPROVED_SEND_TO_CUSTOMER: z.string(),

  
    SMTP_FROM: z.string(),
    SMTP_HOST: z.string(),
    SMTP_PASSWORD: z.string(),
    SMTP_PORT: z.string(),
    SMTP_USER: z.string(),
    UPLOADTHING_APP_ID: z.string(),
    UPLOADTHING_SECRET: z.string(),

    VERCEL_ENV: z.string(),
    METICULOUS_TOKEN: z.string(),

    // Added 9/17/2024
    KV_URL: z.string(),
    KV_REST_API_URL: z.string(),
    KV_REST_API_TOKEN: z.string(),
    KV_REST_API_READ_ONLY_TOKEN: z.string(),

    // Added 9/17/2024
    SYSTEM_LOG_ID: z.string(),
    SCANIFLY_ROUTE_CODE: z.string(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_LOG_ENV: z.enum(["development", "production"]),
    NEXT_PUBLIC_SITE_URL: z.string(),
    NEXT_PUBLIC_CLERK_AFTER_SIGN_IN_URL: z.string(),
    NEXT_PUBLIC_CLERK_AFTER_SIGN_UP_URL: z.string(),
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_UP_URL: z.string(),
    NEXT_PUBLIC_GOOGLE_MAP_API_KEY: z.string(),
    NEXT_PUBLIC_GOOGLE_PLACES_API_KEY: z.string(),
    NEXT_PUBLIC_POSTHOG_KEY: z.string(),
    NEXT_PUBLIC_POSTHOG_HOST: z.string(),
    NEXT_PUBLIC_CLERK_DOMAIN: z.string(),
    NEXT_PUBLIC_AXIOM_DATASET: z.string().optional(),
    NEXT_PUBLIC_AXIOM_TOKEN: z.string().optional(),
    NEXT_PUBLIC_SNAPNRACK_SUPPORT_EMAIL: z.string().optional(),

    // Added 9/20/2024
    NEXT_PUBLIC_MULTIPART_SIZE_LIMIT: z.coerce.number(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NEXT_PUBLIC_LOG_ENV: process.env.NEXT_PUBLIC_LOG_ENV,
    NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    NEXT_PUBLIC_CLERK_AFTER_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_AFTER_SIGN_IN_URL,
    NEXT_PUBLIC_CLERK_AFTER_SIGN_UP_URL: process.env.NEXT_PUBLIC_CLERK_AFTER_SIGN_UP_URL,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL,
    NEXT_PUBLIC_CLERK_SIGN_UP_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_URL,
    NEXT_PUBLIC_GOOGLE_MAP_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
    NEXT_PUBLIC_GOOGLE_PLACES_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
    NEXT_PUBLIC_CLERK_DOMAIN: process.env.NEXT_PUBLIC_CLERK_DOMAIN,
    NEXT_PUBLIC_AXIOM_DATASET: process.env.NEXT_PUBLIC_AXIOM_DATASET,
    NEXT_PUBLIC_AXIOM_TOKEN: process.env.NEXT_PUBLIC_AXIOM_TOKEN,
    NEXT_PUBLIC_SNAPNRACK_SUPPORT_EMAIL: process.env.NEXT_PUBLIC_SNAPNRACK_SUPPORT_EMAIL,

    CC_SUBMISSION_EMAIL: process.env.CC_SUBMISSION_EMAIL,
    BLOB_READ_WRITE_TOKEN: process.env.BLOB_READ_WRITE_TOKEN,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    DATABASE_DIRECT_URL: process.env.DATABASE_DIRECT_URL,
    DATABASE_URL: process.env.DATABASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    PGDATABASE: process.env.PGDATABASE,
    PGHOST: process.env.PGHOST,
    PGPASSWORD: process.env.PGPASSWORD,
    PGUSER: process.env.PGUSER,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    PLAYWRIGHT_BASE_URL: process.env.PLAYWRIGHT_BASE_URL,
    PLAYWRIGHT_CI_USER_EMAIL: process.env.PLAYWRIGHT_CI_USER_EMAIL,
    PLAYWRIGHT_CI_USER_PASSWORD: process.env.PLAYWRIGHT_CI_USER_PASSWORD,
    POSTMARK_API_TOKEN: process.env.POSTMARK_API_TOKEN,
    POSTMARK_TEMPLATE_ACCOUNT_ACTIVATION: process.env.POSTMARK_TEMPLATE_ACCOUNT_ACTIVATION,
    POSTMARK_TEMPLATE_INVITE_USER: process.env.POSTMARK_TEMPLATE_INVITE_USER,
    POSTMARK_TEMPLATE_SIGN_IN: process.env.POSTMARK_TEMPLATE_SIGN_IN,
    POSTMARK_TEMPLATE_PROJECT_SUBMITTED_TO_VENDOR: process.env.POSTMARK_TEMPLATE_PROJECT_SUBMITTED_TO_VENDOR,
    POSTMARK_TEMPLATE_PROJECT_CHANGES_REQUESTED_TO_VENDOR: process.env.POSTMARK_TEMPLATE_PROJECT_CHANGES_REQUESTED_TO_VENDOR,
    POSTMARK_TEMPLATE_PLAN_SET_SUBMITTED_TO_CUSTOMER: process.env.POSTMARK_TEMPLATE_PLAN_SET_SUBMITTED_TO_CUSTOMER,
    POSTMARK_TEMPLATE_PLAN_SET_CHANGES_REQUESTED_TO_CUSTOMER: process.env.POSTMARK_TEMPLATE_PLAN_SET_CHANGES_REQUESTED_TO_CUSTOMER,
    POSTMARK_TEMPLATE_PROJECT_SUBMITTED_TO_VENDOR_NOTIFY_CUSTOMER: process.env.POSTMARK_TEMPLATE_PROJECT_SUBMITTED_TO_VENDOR_NOTIFY_CUSTOMER,
    POSTMARK_TEMPLATE_PLAN_SET_APPROVED_SEND_TO_VENDOR: process.env.POSTMARK_TEMPLATE_PLAN_SET_APPROVED_SEND_TO_VENDOR,
    POSTMARK_TEMPLATE_PLAN_SET_APPROVED_SEND_TO_CUSTOMER: process.env.POSTMARK_TEMPLATE_PLAN_SET_APPROVED_SEND_TO_CUSTOMER,
    SMTP_FROM: process.env.SMTP_FROM,
    SMTP_HOST: process.env.SMTP_HOST,
    SMTP_PASSWORD: process.env.SMTP_PASSWORD,
    SMTP_PORT: process.env.SMTP_PORT,
    SMTP_USER: process.env.SMTP_USER,
    UPLOADTHING_APP_ID: process.env.UPLOADTHING_APP_ID,
    UPLOADTHING_SECRET: process.env.UPLOADTHING_SECRET,
    PUBLIC_ORGANIZATION_ID: process.env.PUBLIC_ORGANIZATION_ID,
    PZSE_URL: process.env.PZSE_URL,
    PZSE_ACCOUNT_ID: process.env.PZSE_ACCOUNT_ID,
    PZSE_CLIENT_ID: process.env.PZSE_CLIENT_ID,
    PZSE_CLIENT_SECRET: process.env.PZSE_CLIENT_SECRET,

    // Added 8/19/2024
    SCANIFLY_API_URL: process.env.SCANIFLY_API_URL,
    SCANIFLY_ACCESS_TOKEN: process.env.SCANIFLY_ACCESS_TOKEN,
    SCANIFLY_PARTNER_ORGANIZATION_ID: process.env.SCANIFLY_PARTNER_ORGANIZATION_ID,
    SCANIFLY_PARTNER_SYSTEM_USER_ID_ID: process.env.SCANIFLY_PARTNER_SYSTEM_USER_ID_ID,

    // Added 9/6/2024
    AMPLIFY_API_KEY: process.env.AMPLIFY_API_KEY,
    AMPLIFY_URL: process.env.AMPLIFY_URL,

    // Added 9/16/2024
    AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
    AWS_REGION: process.env.AWS_REGION,
    AWS_BUCKET_NAME: process.env.AWS_BUCKET_NAME,

    // Added 9/17/2024
    VERCEL_ENV: process.env.VERCEL_ENV,
    METICULOUS_TOKEN: process.env.METICULOUS_TOKEN,

    // Added 9/17/2024
    KV_URL: process.env.KV_URL,
    KV_REST_API_URL: process.env.KV_REST_API_URL,
    KV_REST_API_TOKEN: process.env.KV_REST_API_TOKEN,
    KV_REST_API_READ_ONLY_TOKEN: process.env.KV_REST_API_READ_ONLY_TOKEN,

    // Added 9/17/2024
    SYSTEM_LOG_ID: process.env.SYSTEM_LOG_ID,
    SCANIFLY_ROUTE_CODE: process.env.SCANIFLY_ROUTE_CODE,

    // Added 9/20/2024
    NEXT_PUBLIC_MULTIPART_SIZE_LIMIT: process.env.NEXT_PUBLIC_MULTIPART_SIZE_LIMIT,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
